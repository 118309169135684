<template>
  <div>
    <el-button @click="add">新增</el-button>
    <el-table :data="list" style="width: 100%">
      <el-table-column prop="title" label="标题"> </el-table-column>
      <el-table-column prop="tenant" label="支持学校"> </el-table-column>
      <el-table-column label="操作" width="320">
        <template slot-scope="scope">
          <el-button
            @click="publish(scope.row)"
            >{{ scope.row.publishStatus ? "撤回" : "发布" }}</el-button
          >
          <el-button @click="toDetail(scope.row.url)">查看</el-button>
          <el-button @click="del(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="提示" :visible.sync="show" width="30%">
      <el-form label-width="80px">
        <el-form-item label="名字">
          <el-input v-model="tempParams.title"></el-input>
        </el-form-item>
        <el-form-item label="视频地址">
          <el-input v-model="tempParams.url"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input
            v-model="tempParams.coursewareDesc"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item label="版本范围">
          <el-input v-model="tempParams.ranges"></el-input>
        </el-form-item>
        <el-form-item label="学校id">
          <el-input v-model="tempParams.tenantIds"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button @click="confirm">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      params: {
        pageIndex: 1,
        pageSize: 99,
      },
      show: false,
      tempParams: {
        title: "",
        url: "",
        coursewareDesc: "",
        ranges: "",
        tenantIds: "",
      },
    };
  },
  methods: {
    publish(item) {
      this.$ajax
        .post("lv/admin/courseware/publish", {
          id: item.id,
          publishStatus: item.publishStatus ? 0 : 1,
        })
        .then((res) => {
          this.getList();
        });
    },
    confirm() {
      this.$ajax
        .post("lv/admin/courseware/save", this.tempParams)
        .then((res) => {
          this.show = false;
          this.getList();
        });
    },
    add() {
      this.show = true;
    },
    toDetail(url) {
      window.open(url);
    },
    getList() {
      this.$ajax
        .post("lv/admin/courseware/getList", this.params)
        .then((res) => {
          this.list = res.list;
        });
    },
    del(id) {
      this.$ajax.post("lv/admin/courseware/delete", { id }).then((res) => {
        this.getList();
      });
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style>
</style>